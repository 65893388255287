<script lang="ts" setup>
const {
  html,
  allowedTags = [],
  allowedClasses = undefined,
  byTagName = undefined,
} = defineProps<{
  html: string
  allowedTags?: string[]
  allowedClasses?: string[]
  byTagName?: keyof typeof tagNamePresets
}>()

const inlineTags = [
  'a',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'img',
  'kbd',
  'mark',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'svg',
  'time',
  'u',
  'var',
  'wbr',
]

const tagNamePresets = {
  h1: inlineTags,
  h2: inlineTags,
  h3: inlineTags,
  h4: inlineTags,
  h5: inlineTags,
  span: inlineTags,
  small: inlineTags,
  // TODO: Add your tag if needed
}

const sanitizeHtml = (await import('sanitize-html')).default

const sanitizeOptions = computed(() => ({
  ...(byTagName && { allowedTags: tagNamePresets[byTagName] }),
  ...(allowedTags && { allowedTags }),
  ...(allowedClasses && {
    allowedClasses: { '*': allowedClasses },
  }),
}))

const sanitizedHtml = computed(() => sanitizeHtml(html, sanitizeOptions.value).replace(/<br \/>/g, '<br>'))
</script>

<template>
  <slot :html="sanitizedHtml" />
</template>
